import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["add_item", "template"]
  static values = {
    replacement: { type: String, default: "-1986" }
  }

  connect() {
    console.log("NestedFormController connected")
  }

  add_association(event) {
    event.preventDefault()
    let content = this.templateTarget.innerHTML

    content = content.replace(new RegExp(this.replacementValue, "g"), new Date().getTime())

    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_association(event) {
    event.preventDefault()

    let item = event.target.closest(".nested-fields")

    if (item.dataset.templateRecord == "true") {
      item.remove()
    } else {
      item.querySelector("input[name*='_destroy']").value = 1
      item.style.display = 'none'
    }
  }
}
